import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import sponsors from '../assets/sponsors.svg'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Zoom from '@material-ui/core/Zoom';
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";

const useStyles = makeStyles((theme:Theme) => ({
    headerContainer: {
        position: 'relative',
        textAlign: 'center',
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            height: '10rem',
        },
    },
    headerImage: {
        top: 0,
        left: 0,
        backgroundPosition: 'center 0',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '100%',
        objectFit: 'none',
        [theme.breakpoints.down('lg')]: {
            marginTop: '-1rem',
        },
        [theme.breakpoints.down('sm')]: {
            height: '100%',
        },
    },
    headerText: {
        ...theme.typography.tab,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textShadow: '3px 3px #000000',
        padding: '10px',
        fontSize: '36px',
    },
    heroText: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '10px',
        animation: `heroEffect 1s ease-out 0s 1 slideInFromLeft`
    },
    "@keyframes heroEffect": {
        "0%": {
            opacity: 0,
            transform: "translateY(-200%)"
        },
        "100%": {
            opacity: 1,
            transform: "translateY(0)"
        }
    },
    eyscLogo: {
        height: '12rem',
        [theme.breakpoints.down('md')]: {
            height: '8rem',
        },
        [theme.breakpoints.down('sm')]: {
            height: '5rem',
        },

    },
    missionStatement: {
        ...theme.typography.tab,
        textAlign: 'center',
        fontSize: '1.7rem',
        padding: '2rem',
    },
    imageGrid: {
        width: '100%',
    },
    imageGridContainer: {
        ...theme.typography.tab,
        position: 'relative',
        textAlign: 'center',
        color: 'white',
        opacity: 0.8,
        '&:hover': {
            opacity: 1,
            color: theme.palette.secondary.main,
        },
        [theme.breakpoints.down('md')]: {

        },
    },
    serviceContainer: {
        marginTop: "3rem",
        [theme.breakpoints.down("sm")]: {
            padding: 25
        }
    },
    signUpContainer: {
        backgroundColor: theme.palette.secondary.main,
        marginTop: "2rem",
        [theme.breakpoints.down("sm")]: {
            padding: 25,
            marginTop: "1em"
        }
    },
    specialText: {
        fontFamily: "Pacifico",
        color: theme.palette.secondary.main,
    },
    subtitle: {
        marginBottom: "1em"
    },
    title: {
        marginTop: "1em"
    },
    icon: {
        height: '15rem',
        color: 'red',
        marginLeft: "2em",
        [theme.breakpoints.down("xs")]: {
            marginLeft: 0
        }
    },
    descContainer: {
        maxWidth: '45rem',
    },
    button: {
        borderRadius: '50px',
        fontFamily: 'Indie Flower',
        textTransform:"none",
        fontWeight:'bold',
        fontSize: '1.5rem',
        height: '45px',
        margin: '1rem',
        [theme.breakpoints.down('sm')]: {
            height: '80px',
        },
    },
    dialogTitle: {
        backgroundColor: theme.palette.secondary.main,
    },
}));

const Home = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const currentDate = new Date();
    const registrationCloseDate = new Date("April 21, 2023 00:00:00");
    const isRegistrationOpen = currentDate < registrationCloseDate;
    const [openDialog, setOpenDialog] = React.useState(isRegistrationOpen);
    const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const tryoutsDialog = (
        <React.Fragment>
            <Dialog open={openDialog} onClose={handleDialogClose}
                    PaperProps={{style: { borderRadius: 10 }}}>
                <DialogTitle className={classes.dialogTitle}>{t('tryoutDialog.title')}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        {t('tryoutDialog.text1')}<br/>
                        {t('tryoutDialog.dates')}<br/>
                        {t('tryoutDialog.cost')}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        component={'a'}
                        href='https://playmetrics.com/signup?clubToken=TG9naW4tQ2x1Yi52MS0xMzQ2LTE3NDMxMjEzNDZ8ZTRQb0ZFS1YrYyszWE5QajRJY2xhazFUYzdFT1BQeHBuNm0rZDdzRjVrRT0=&program_id=52186'
                        rel='noreferrer noopener'
                        target={'_blank'}
                        variant='contained'
                        color='secondary'
                    >{t('tryoutDialog.register')}</Button>
                    <Button variant='contained'
                            color='secondary'
                            onClick={handleDialogClose}
                    >{t('tryoutDialog.close')}</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );

    return (
        <React.Fragment>
            {isRegistrationOpen ? tryoutsDialog: null}
            <HelmetProvider>
            <Helmet>
                <script async defer crossOrigin="anonymous"
                        src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v15.0"
                        nonce="w7hhZZUb">

                </script>
            </Helmet>
            <div className={classes.headerContainer}>
                <img src={require('../assets/home_header.jpg')}
                     alt='EYSC Soccer Banner'
                     className={classes.headerImage}
                />
                <div className={classes.heroText}>
                    <Zoom
                        in={true}
                        mountOnEnter
                        style={{ transitionDelay: '500ms'}}
                       {...{ timeout: 1000 }}
                    >
                            <img src={require('../assets/EYSC-stacked-logo-lg.png')}
                                 alt='EYSC Name'
                                 className={classes.eyscLogo}
                            />
                    </Zoom>
                </div>
            </div>
            <div className={classes.missionStatement}>
                {t('home.missionStmt')}
            </div>

                <Grid container spacing={0} justifyContent={'center'}>
                    <Grid item lg={4} sm={6} xs={12} component={Link} to={'/programs/philosophy/'}>
                        <div className={classes.imageGridContainer}>
                            <img src={require('../assets/home-philosophy.jpeg')}
                                 alt='EYSC Philosophy'
                                 className={classes.imageGrid}
                            />
                            <div className={classes.headerText}>
                                {t('home.philosophy')}
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={4} sm={6} xs={12} component={Link} to={'/registration/'}>
                        <div className={classes.imageGridContainer}>
                            <img src={require('../assets/home-registration.jpeg')}
                                 alt='EYSC Registration'
                                 className={classes.imageGrid}
                            />
                            <div className={classes.headerText}>
                                {t('home.registration')}
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={4} sm={6} xs={12} component={Link} to={'/programs/select-info-fees/'}>
                        <div className={classes.imageGridContainer}>
                            <img src={require('../assets/home-tryouts.jpeg')}
                                 alt='EYSC Tryouts'
                                 className={classes.imageGrid}
                            />
                            <div className={classes.headerText}>
                                {t('home.tryouts')}
                            </div>
                        </div>
                    </Grid>
                </Grid>

                <Grid container direction="column">
                    <Grid item>
                        {" "}
                        {/*-----Sign up to stay in touch Block-----*/}
                        <Grid
                            container
                            direction="row"
                            justifyContent={matchesSM ? "center" : undefined}
                            className={classes.signUpContainer}
                        >
                            <Grid
                                item
                                style={{
                                    marginLeft: matchesSM ? 0 : "5em",
                                    marginRight: matchesSM ? 0 : "5em",
                                    textAlign: matchesSM ? "center" : undefined
                                }}
                            >
                                <Typography variant="h4" className={classes.title}>{t('home.stayInTouch')} </Typography>
                                <Typography variant="subtitle2" className={classes.subtitle}>
                                    {t('home.stayInTouchInfo')}
                                </Typography>
                            </Grid>
                            <Grid item className={classes.title} >
                                {/*//TODO do we need to route to a different page?*/}
                                <Button
                                    component={'a'}
                                    href="https://everettyouthsoccerclub.us3.list-manage.com/subscribe?u=db04b6c73c73d35b990af4f03&id=d83999181d"
                                    rel='noreferrer noopener'
                                    target={'_blank'}
                                    className={classes.button}
                                    variant='contained'
                                    color='primary'
                                    // onClick={() => {
                                    //     props.setValue(1);
                                    //     props.setSelectedIndex(2);
                                    // }}
                                >
                                    {t('home.signUpBtn')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        {" "}
                        {/*-----Follow us on FB Block-----*/}
                        <Grid
                            container
                            direction="row"
                            justifyContent={matchesSM ? "center" : undefined}
                            className={classes.serviceContainer}
                        >
                            <Grid
                                item
                                style={{
                                    marginLeft: matchesSM ? 0 : "5em",
                                    marginRight: matchesSM ? 0 : "3em",
                                    textAlign: matchesSM ? "center" : undefined
                                }}
                            >
                                <Typography variant="h4">{t('home.followUsFB')}</Typography>
                                <Typography variant="subtitle1" className={classes.subtitle}>
                                    {t('home.fbWhatsNew')}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <div className="fb-page" data-href="https://www.facebook.com/EverettYouthSoccerClub/" data-tabs="timeline"
                                     data-width="" data-height="" data-small-header="false" data-adapt-container-width="true"
                                     data-hide-cover="false" data-show-facepile="true">
                                    <blockquote cite="https://www.facebook.com/EverettYouthSoccerClub/" className="fb-xfbml-parse-ignore"><a
                                        href="https://www.facebook.com/EverettYouthSoccerClub/">Everett FC / Everett Youth Soccer Club</a>
                                    </blockquote>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        {" "}
                        {/*-----Checkout our Sponsors Block-----*/}
                        <Grid
                            container
                            direction="row"
                            justifyContent={matchesSM ? "center" : "flex-end"}
                            className={classes.serviceContainer}
                        >
                            <Grid
                                item
                                style={{
                                    textAlign: matchesSM ? "center" : undefined
                                }}
                                className={classes.descContainer}
                            >
                                <Typography variant="h4">{t('home.sponsors')}</Typography>
                                <Typography variant="subtitle1" className={classes.subtitle}>
                                    {t('home.sponsorsInfo')}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {t('home.sponsorsInfo2')}
                                    {matchesSM ? null : <br />}{t('home.sponsorsInfo3')}
                                </Typography>
                                <Button
                                    component={Link}
                                    to="/about/sponsors/"
                                    className={classes.button}
                                    variant='contained'
                                    color='secondary'
                                    // onClick={() => {
                                    //     props.setValue(1);
                                    //     props.setSelectedIndex(2);
                                    // }}
                                >
                                    {t('home.sponsorBtn')}
                                </Button>
                            </Grid>
                            <Grid item style={{ marginRight: matchesSM ? 0 : "5em" }}>
                                <img
                                    className={classes.icon}
                                    alt="sponsors icon"
                                    src={sponsors}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>


            </HelmetProvider>
        </React.Fragment>
    );
};

export default Home;